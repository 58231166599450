export const squad = {
  list: '班级列表',
  from: '班级操作',
  view: '班级详情',
  create: '新建班级',

  organization_info: '机构信息',
  squad_info: '班级信息',

  name_list: '班级花名册',

  info: '班级信息',
  squad_no: '班级编号',
  picture: '班级封面',
  teacher_name: '班主任姓名',
  title: '班级名称',
  start_time: '开班时间',
  end_time: '结业时间',
  content: '培训计划',
  total: '班级人数',
  start_class: '开课',
  stop_class: '停课',
  open_status: '开课状态',


  start_stop_time: '起止时间',
  course_list: '培训科目',

  rules: {
    teacher_id: {
      require: '班主任不能为空',
    },
    title: {
      require: '班级名称不能为空',
    },
    valid_time: {
      require: '起止时间不能为空',
    },
    total: {
      require: '班级人数不能为空',
    },
  },

  audit: {
    title: '班级审核',
    content: '审核意见',

    rules: {
      audit_status: {
        require: '审核状态不能为空',
      }
    }
  }
}
