export const course = {
  list: '课程列表',
  from: '课程操作',
  view: '课程详情',

  basic_info: '基本信息',
  detail_info: '体验信息',
  resource_info: '课程资料',
  emphasis_info: '课程重点',
  intensify_info: '强化',

  info: '课程信息',
  course_structure: '结构',
  course_point: '知识点',
  course_unit: '单元',
  course_experience: '体验',
  course_resource: '课程资料',
  course_intensify: '考前强化',

  type: '课程类型',
  title: '课程标题',
  picture: '课程封面',
  time_length: '课程时长',
  category_id: '课程分类',
  label_id: '课程标签',
  money: '课程费用',
  resource: '课程资源',
  description: '课程介绍',
  content: '体验内容',
  create_time: '添加时间',
  is_recommend: '首页推荐',
  status: '课程状态',
  member_total: '学习人数',
  organization_total: '订购机构',
  video_total: '视频数量',
  interaction_total: '交互题数量',
  outline: '培训大纲',

  enable: '已推荐',
  disable: '未推荐',

  rules: {
    title: {
      require: '课程标题不能为空',
    },
    picture: {
      require: '课程封面不能为空',
    },
    description: {
      require: '课程介绍不能为空',
    },
    money: {
      require: '课程费用不能为空',
    },
    type: {
      require: '课程类型不能为空',
    },
    category_id: {
      require: '课程分类不能为空',
    },
  },

  unit: {
    list: '单元列表',
    from: '单元操作',
    view: '查看单元',

    title: '单元标题',
    parent_title: '上级标题',
    picture: '单元图片',
    description: '单元介绍',
    depth: '单元层级',
    is_column: '单元栏目',

    rules: {
      title: {
        require: '单元标题不能为空',
        length: '单元标题最大长度为50',
      },
      picture: {
        require: '单元封面不能为空',
      },
    },

    point: {
      list: '知识点列表',
      from: '知识点操作',
      view: '查看知识点',
      create: '新建知识点',
      resource_info: '知识点资料',

      basic_info: '基本信息',
      detail_info: '详细内容',
      question_info: '练习题',
      resource_info: '知识点资料',
      emphasis_info: '课程重点',

      info: '知识点信息',
      course_unit: '课程单元',

      type: '知识点类型',
      title: '知识点标题',
      picture: '知识点封面',
      description: '知识点介绍',
      content: '知识点内容',
      time_length: '知识点时长',
      study_total: '学习总数',
      study_finish_total: '学习完成总数',
      create_time: '添加时间',
      is_recommend: '首页推荐',
      resource: '知识点资源',
      status: '知识点状态',

      rules: {
        title: {
          require: '知识点标题不能为空',
        },
        picture: {
          require: '知识点封面不能为空',
        },
        money: {
          require: '知识点费用不能为空',
        },
      },

      emphasis: {
        info: '重点信息',

        title: '重点标题',
        content: '重点内容',
      }
    },
  },

  intensify: {
    list: '考前强化列表',
    from: '考前强化操作',
    view: '考前强化详情',
    point_info: '考点',

    title: '考前强化标题',
    parent_title: '上级标题',
    picture: '考前强化图片',

    rules: {
      title: {
        require: '考前强化标题不能为空',
        length: '考前强化标题最大长度为50',
      }
    },

    point: {
      list: '考点列表',
      from: '考点操作',
      view: '考点详情',

      text: '图文内容',
      question: '强化题内容',
      paper: '模拟考核试卷内容',

      title: '考点标题',
      content: '考点内容',

      rules: {
        title: {
          require: '强化标题不能为空',
          length: '标签标题最大长度为200',
        },
        content: {
          require: '强化内容不能为空',
        },
      }
    },
  },

  category: {
    list: '分类列表',
    from: '分类操作',
    view: '分类详情',

    title: '分类标题',

    rules: {
      title: {
        require: '分类标题不能为空',
        length: '分类标题最大长度为50',
      },
    }
  },


  label: {
    list: '标签列表',
    from: '标签操作',
    view: '标签详情',

    title: '标签标题',

    rules: {
      title: {
        require: '标签标题不能为空',
        length: '标签标题最大长度为50',
      },
    }
  },


  emphasis: {
    info: '重点信息',

    title: '重点标题',
    content: '重点内容',
  },

  structure: {
    data: '课程结构'
  },
}
