export const question = {

  course: {
    list: '课后练习题列表',
    from: '课后练习题操作',
    view: '查看课后练习题',
    create: '新建课后练习题',
    basic_info: '基本信息',
    question_info: '试题信息',
    answer_info: '答案信息',

    info: '试题信息',
    title: '试题标题',
    description: '试题描述',
    url: '试题资源',
    type: '试题类型',
    content: '试题内容',
    answer: '试题答案',
    status: '试题状态',
    create_time: '添加时间',

    type: '类型',
    level: '难度',
    score: '分值',
    content: '题目内容',
    category: '分类',
    optionA: '选项A',
    optionB: '选项B',
    optionC: '选项C',
    optionD: '选项D',
    optionE: '选项E',
    optionF: '选项F',
    answer: '参考答案',
    analysis: '试题解析',
    placeholder_input_option: '请先输入选项再添加',

    choices: '选择题',
    judgement: '判断题',
    complete: '填空题',
    explain: '名词解析题',
    connection: '连线题',

    correct: '正确',
    error: '错误',

    rules: {
      title: {
        require: '试题标题不能为空',
      },
      options: {
        require: '试题内容不能为空',
      },
      answer: {
        require: '试题答案不能为空',
      },
    },

    option: {
      create: '新增',
      title: '答案序号',
      value: '答案内容',
      url: '答案资源地址',
      result: '选项结果',
      position: '正确位置',
    }
  },


  intensify: {
    list: '强化练习题列表',
    from: '强化练习题操作',
    view: '查看强化练习题',
    create: '新建强化练习题',
  },


  paper: {
    list: '试卷列表',
    from: '试卷操作',
    view: '试卷详情',
    question_info: '试题',

    title: '试卷名称',
    description: '试卷描述',
    test_time: '考试时长(分钟)',
    paper_question: '试卷试题',
    organization_total: '机构数量',
    user_total: '用户数量',

    rules: {
      title: {
        require: '试卷标题不能为空',
      },
      test_time: {
        require: '考试时长不能为空',
      },
    },

    question: {
      list: '试题列表',
      from: '试题操作',
      view: '试题详情',
    },

    statistical: {
      basic_info: '数据统计',
      visual_info: '可视化统计',

      answer_total: '答题人数',
      answer_time: '答题平均时长',
      highest_score: '最高得分',
      average_score: '平均得分',
      lowest_score: '最低得分',
    }
  },
}
